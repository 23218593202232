<template>
  <v-navigation-drawer :value="isAddNewEventSidebarActive" :permanent="isAddNewEventSidebarActive" temporary touchless :right="!$vuetify.rtl" :width="'20%'" app
    @input="(val) => $emit('update:is-add-new-item-sidebar-active', val)">
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Event</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-item-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field v-model="itemData.name" :rules="[validators.customeRequired]" outlined dense label="Name"
                placeholder="Event" hide-details="auto" class="mb-6"></v-text-field>



              <v-menu v-model="modal" :close-on-content-click="false" :nudge-left="310"  transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="itemData.start_date" :rules="[validators.required]" label="Start Date"
                    :prepend-icon="icons.mdiCalendar" readonly v-bind="attrs" v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker :min="today" v-model="itemData.start_date" color="primary" @input="modal = false">
                </v-date-picker>
              </v-menu>



              <v-menu v-model="menu2" :close-on-content-click="false"  :nudge-left="310" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="itemData.end_date" :rules="[validators.required]" label="End Date"
                    :prepend-icon="icons.mdiCalendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker :min="itemData.start_date? itemData.start_date :today" v-model="itemData.end_date" color="primary" @input="menu2 = false">
                </v-date-picker>
              </v-menu>

              <v-textarea v-model="itemData.description" outlined label="Description" :rules="[validators.required]"
                placeholder="Description">
              </v-textarea>

              <v-text-field v-model="itemData.mobile_number" :rules="[validators.required, validators.integerValidator]"
                outlined dense label="Mobile Number" placeholder="+962791152439" hide-details="auto" type="number"
                hide-spin-buttons class="mb-6"></v-text-field>


              <v-text-field v-model="itemData.mobile_number_second" outlined dense label="Another Mobile Number"
                placeholder="+962791152439" hide-details="auto" type="number" hide-spin-buttons class="mb-6">
              </v-text-field>

              <v-text-field v-model="itemData.email" :rules="[validators.required, validators.emailValidator]" outlined
                dense label="Email" placeholder="example@something.com" hide-details="auto" class="mb-6"></v-text-field>

              <v-file-input v-model="itemData.image" accept="image/png, image/jpeg, image/bmp"
                placeholder="Choose an image" outlined type="file" dense label="Upload Image" class="mb-1"
                prepend-icon=""
                hint="Image dimensions: min-width: 833px, max-width: 1670px, min-height: 625px, max-height: 1253px, max-file-size: 512kb |dimensions:ratio=4/3"
                :rules="[validators.imageValidator]"
                @click="hideHint()">
              </v-file-input>
              <span v-if="showHint" class="mb-6" style="display:block;font-size: 11px;">Image dimensions: min-width: 833px, max-width: 1670px, min-height: 625px, max-height: 1253px, max-file-size: 512kb |dimensions:ratio=4/3</span>
            </v-col>
          </v-row>

          <v-btn color="primary" class="me-3" type="submit">
            Add
          </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetItemData">
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store'
import {
  customeRequired,
  emailValidator,
  fileRequired,
  imageValidator,
  integerValidator,
  required,
} from '@core/utils/validation'
import { mdiClipboardFileOutline, mdiClose, mdiDelete, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      showHint: true,
    }
  },
  model: {
    prop: 'isAddNewEventSidebarActive',
    event: 'update:is-add-new-item-sidebar-active',
  },
  props: {
    isAddNewEventSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    hideHint() {
      this.showHint = false
    },
  },
  setup(props, { emit }) {
    const modal = ref(false)
    const menu2 = ref(false)

    var date = new Date()
    var dd = String(date.getDate()).padStart(2, '0')
    var mm = String(date.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = date.getFullYear()
    const today = yyyy + '-' + mm + '-' + dd

    const blankItemData = {
      name: '',
      end_date: '',
      start_date: '',
      remaining_time: '',
      mobile_number: '',
      end_date: '',
      description: '',
      email: '',
      image: [],
    }

    const isError = ref(false)
    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const itemData = ref(JSON.parse(JSON.stringify(blankItemData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankItemData))
      resetForm()
      emit('update:is-add-new-item-sidebar-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        store
          .dispatch('app-events/addEvent', itemData.value)
          .then(response => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: response.data.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
            emit('refetch-data')
            emit('update:is-add-new-item-sidebar-active', false)
          })
          .catch(error => {
            Object.entries(error.data.message).forEach(err => {
              Swal.fire({
                icon: 'error',
                title: `${err[0]}`,
                text: `${err[1][0]}`,
                footer:
                  'Desired dimensions: min-width: 833px; max-width: 1670px; min-height: 625px; max-height: 1253px; max-file-size: 512kb',
              })
            })
          })
        resetItemData()
      } else {
        validate()
      }
    }

    const handleAler = () => {
      isError.value = false
    }

    return {
      resetItemData,
      form,
      today,
      onSubmit,
      itemData,
      valid,
      validate,
      isError,
      handleAler,
      blankItemData,
      modal,
      menu2,
      // validation
      validators: {
        integerValidator,
        required,
        imageValidator,
        fileRequired,
        customeRequired,
        emailValidator,
      },
      icons: {
        mdiClose,
        mdiClipboardFileOutline,
        mdiPlus,
        mdiDelete,
      },
    }
  },
}
</script>
