import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useEventsList() {
  const eventsListTable = ref([])

  const tableColumns = [
    { text: 'Event', value: 'name' },
    { text: 'Start Date', value: 'start_date' },
    { text: 'End Date', value: 'end_date' },
    { text: 'Description', value: 'description' },
    { text: 'Image', value: 'image' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const planFilter = ref(null)
  const totalEventsListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
    q:searchQuery.value
  })
  const packagesTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchEvents = () => {
    options.value.q = searchQuery.value
    store
      .dispatch('app-events/fetchEvents', {
        q:searchQuery.value,
        options: options.value,
        plan: planFilter.value,
      })
      .then(response => {
        const { filteredData, total, eventsTotal } = response.data.data
        eventsListTable.value = filteredData
        totalEventsListTable.value = total
        packagesTotalLocal.value = eventsTotal

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.error(error)
      })
  }

  watch([searchQuery, planFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchEvents()
  })

  return {
    eventsListTable,
    planFilter,
    searchQuery,
    tableColumns,
    totalEventsListTable,
    loading,
    options,
    packagesTotalLocal,
    selectedRows,
    fetchEvents,
  }
}
